<template>
   <v-container>
      <crud
         api-path="category"
         title="Kategori"
         :field="field"
         key-id="id"
         with-edit
         ref="crud"
      >
      <template v-slot:row_act="dat">
         <v-btn v-if="dat.category_active_status===0" class="mr-auto" color="green" icon @click="activate(dat)" title="activate"><v-icon>mdi-check</v-icon></v-btn>
         <v-btn v-if="dat.category_active_status===1" class="mr-auto" color="red" icon @click="activate(dat)" title="inactivate"><v-icon>mdi-cancel</v-icon></v-btn>
      </template>
      </crud>
   </v-container>
</template>

<script>
import crud from '@/components/crud';
import api from '../api';

export default {
   components: { crud },
   data: () => ({
      field: [
         { value: 'category_id', label: '#', ro: true, tbl: true },
         { value: 'category_name', label: 'Nama', tbl: true, rules: [v => !!v || 'Nama harus diisi'], searchKey: true },
         { value: 'category_active_status', label: 'Status', ro: true, tbl: true, filter: true, master: ['category_active_status_name', 'status_id','status_name','list/activation_status']},
         { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
      ],
      actions: [
         {icon: 'edit', fn: 'openForm', color: 'indigo'},
      ]
   }),
   methods: {
      activate(dat) {
         const val = dat.category_active_status===1;
         this.$alert({ text: `${val?'Non-aktifkan':'Aktifkan'} kategori ini ?`, type: 'warning',
            ok: () => {
               api.edit('category',`${dat.category_id}/${val?'inactivate':'activate'}`).then(rsl => {
                  if(rsl.error) {
                     this.$alert('Ooops!', rsl.error, 'error');
                  }
                  if(rsl.success) {
                     this.$alert({ title: 'Success', text: rsl.success, type: 'success', timer: 2000 });
                     this.$refs.crud.getData();
                  }
               });
            },
            cancel: () => {}
         });
      },
   }
};
</script>
